import React from 'react'
import Navbar from '../../components/navbar'
import BlogMain from '../../../static/images/insurance.jpg'


export default function hrstrategy() {
  return (
    <div>
       <Navbar/>
<section class=" pb-16 lg:pb-24 bg-white" style={{fontFamily:'poppins'}}>
  <div class="h-64 lg:h-144">
    <img class="w-full h-full object-cover" src={BlogMain} alt=""/>
  </div>
  <div class="relative container px-4 mx-auto -mt-24">
    <div class="max-w-5xl px-4 pt-12 lg:pt-20 mx-auto bg-gray-100">
      <div class="max-w-2xl mb-12 mx-auto text-center">
        <div class="flex items-center justify-center mb-6 text-xs text-blue-500 font-semibold uppercase">
          <a href="#">Home</a>
          <span class="inline-block mx-2">
            <svg class="text-blue-500 h-3 w-3" viewbox="0 0 8 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.875 0L0 1.875L4.125 6L0 10.125L1.875 12L7.875 6L1.875 0Z"></path>
            </svg>
          </span>
          <a href="#">Blog</a>
          <span class="inline-block mx-2">
            <svg class="text-blue-500 h-3 w-3" viewbox="0 0 8 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.875 0L0 1.875L4.125 6L0 10.125L1.875 12L7.875 6L1.875 0Z"></path>
            </svg>
          </span>
          <a href="#">Article</a>
        </div>
        <h2 class="mb-6 text-3xl lg:text-4xl text-indigo-800 font-bold font-heading">Underwriting – An Introduction from Actuarial Perspective</h2>
        <div class="flex items-center justify-center">
          {/* <div class="mr-6">
            <img class="w-14 h-14 object-cover" src="https://images.unsplash.com/photo-1607746882042-944635dfe10e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1050&amp;q=80" alt=""/>
          </div> */}
          <div class="text-left">
            {/* <h3 class="mb-2 text-lg text-gray-500 font-semibold font-heading">Sara Sampton</h3> */}
            <p class="text-blue-500 text-xs text-center">Published On June 7, 2022
</p>
          </div>
        </div>
      </div>
      <div class="max-w-3xl mx-auto -mb-6 ">
        <p class="mb-6  text-md lg:text-lg text-gray-600">Underwriting is an integral part of the overall premium setting cycle for any insurance company.

It is the process by which an insurance company assesses its risk. Moreover, it also assists an insurer in determining whether or not providing coverage to a person or business is lucrative.

Based on the data shared by proposer, Underwriters evaluate and segment Insurance risk as standard and Non-standard risks. This further helps to quote appropriate premium rates based on underlying risk profile and also decide upon the form and cover to be provided.

Underwriting is a critical component of all types of insurance. Individuals or corporations entrust their risks to an insurer, who charges a fee in exchange for providing financial help in the event of a loss. Nevertheless, before issuing an insurance policy, insurers must first comprehend the nature and breadth of the risk they’re taking on, which necessitates underwriting.

</p> 
        <p class="mb-6 font-semibold text-md lg:text-lg text-gray-600">Scope of Underwriting
</p>
        <p class="mb-6  text-md lg:text-lg text-gray-600">Underwriting itself is the foundation of the insurance sector. That is why it is critical for underwriters to make the best selections possible. It is up to them, and no one else, to ensure that the industry is exposed to the appropriate level of risk and that this risk is matched by the appropriate premium. If this equilibrium is lost, the industry will be put in jeopardy. The poison of bad underwriting impacts the entire industry. Good underwriting is beneficial to all.</p>
      
        <p class="mb-6  text-md lg:text-lg text-gray-600">“In any type of insurance, whether life or non-life, the underwriting procedure is critical. If the risk is incorrectly assessed, the premium charged will be inappropriate. A decreased premium may have an impact on the fund’s solvency. A decision to impose a premium that is more than necessary, on the other hand, would be unjust.”</p>

        <p class="mb-6   text-md lg:text-lg text-gray-600">Underwriting is vital in any type of insurance, whether life or non-life. If the risk is incorrectly assessed, the premium charged will be inappropriate. A decreased premium may have an impact on the fund’s solvency. The rest of the policyholders will have to bear the expense of the additional risk that was not collected from the proposer. A choice to charge a premium that is more than necessary, on the other hand, would not be fair to the proposer. As a result, underwriting has ramifications for both the insurer and the policyholders, both individually and collectively, in terms of fairness.</p>

        <p class="mb-6   text-md lg:text-lg text-gray-600">Basically, underwriting’s goal is risk classification, which means accepting as many lives as possible at regular premium rates, leaving a tiny fraction for extra premium acceptance, and an even smaller number that should be denied or deferred.</p>

        <p class="mb-6 font-semibold text-md lg:text-lg text-gray-600">Role of an Underwriter</p>

        <p class="mb-6 text-md lg:text-lg text-gray-600">There are a few features that all the top underwriters share. Consistency is essential for excellent underwriting. Underwriters must assess each risk fairly and price it as affordably as possible. This also necessitates a long-term perspective on risk, as well as the ability to keep an open mind on occasion. Many insurers will automatically reject a risk due to its peculiar nature. That isn’t to say it isn’t a risk worth taking. Underwriters require the freedom to classify risks that don’t fit neatly into any of the categories. Not only does a successful underwriter engage with individuals on a human level, but he or she also connects with them on a human level. Meeting with someone in person allows an underwriter to gain a deeper understanding of the risk, as well as the mind-set and ambitions that are driving it. This distinguishes the underwriter. When a client needs a risk underwritten, having that human relationship with an underwriter makes them the first port of call. It also helps if the underwriter responds quickly. Even a simple message stating that you have received someone’s submission might go a long way.</p>

        <p class="mb-6  text-md lg:text-lg text-gray-600">Underwriting is a large element of the industry, and poor underwriting can harm the entire industry. So set the correct price, treat people well, and make sure you’ve covered all your bases.

</p>

<p class="mb-6 font-semibold text-md lg:text-lg text-gray-600">Actuaries and Underwriters
</p>

<p class="mb-6   text-md lg:text-lg text-gray-600">Actuaries and Underwriters work hand in hand in deciding the acceptable level of risk and the premium chargeable on the same.   Actuaries work with underwriters in various ways like designing the underwriting guidelines, building underwriting tools and premium raters to calculate premium easily and identify various risk that a policy may bring.

</p>

<p class="mb-6 text-md lg:text-lg text-gray-600">Actuarial models are built to price at cohort/segment level (consisting of homogeneous risks) not Individual level. With the advancement of technology and sophisticated models, the distinction between the two roles is diminishing.
</p>

<p class="mb-6   text-md lg:text-lg text-gray-600">Technology is an area where the two can work together to bring in the right amalgamation of data and (on-field) business knowledge.</p>

<p class="mb-6  text-md lg:text-lg text-gray-600">RJ Actuaries and Consultants (RJAC) has a team of experienced professionals who have worked alongside Underwriters for years and are capable of understanding and catering to the needs of an Insurance Company from an underwriting support perspective.
</p>


<p class="mb-6  text-md lg:text-lg text-gray-600">At RJAC, we are supporting the cause by building tools built by Actuaries for Underwriters to make right risk selection based on data.

</p>

<p class="mb-6  pb-10 text-md lg:text-lg text-gray-600">Connect with us to know more about how we can help your Underwriting department from an actuarial point of view.



</p>

       </div>
    </div>
  </div>
</section>



    </div>
  )
}
